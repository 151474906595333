import PublicPageLayout from "@/layout/PublicPageLayout";

import OauthOkta from "./login/OauthOkta";

export default function Login() {
  return (
    <PublicPageLayout>
      <div className="w-full max-w-80 mb-4 border border-border bg-white shadow-lg text-neutral-600 self-center flex flex-col justify-center p-7 rounded-md">
        <h1 className="font-light text-xl mb-5 u-text-center">
          Sign in to your Account
        </h1>
        <div>
          <OauthOkta />
        </div>
      </div>
    </PublicPageLayout>
  );
}
