import { AccountsTable } from "./components/accounts-table";
import { AccountsRoleBuilderDrawer } from "./components/accounts-role-builder-drawer";

export const AccountsPage = () => {
  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl pb-4">
          SupportRent Accounts
        </h1>
        <AccountsRoleBuilderDrawer />
      </div>

      <AccountsTable />
    </div>
  );
};
