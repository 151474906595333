import * as React from "react";
import { TouchableOpacity, View, StyleSheet } from "react-native";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { Stack, TextInputField, useTheme } from "@smartrent/ui";
import { CloseCircleSolid, Hamburger, Search } from "@smartrent/icons";

import { useIsMobileCard } from "@/hooks/breakpoints";

import Layout from "@/layout/Layout";

import { useUser } from "@/layout/Context";
import Helpers from "@/lib/helpers";
import { ManagerQuickActions, QuickActions } from "@/common/links/SmartLinks";
import { ProjectList } from "@/common/projects/ProjectList";
import RadioFilters from "@/common/RadioFilters";
import { useDrawerNav } from "@/common/AppDrawer";
import { BatteryPoweredThermostats } from "@/pages/group/sections/BatteryPoweredThermostats";
import { useGroup } from "@/pages/group/GroupContext";
import { GroupAlerts } from "@/pages/group/GroupAlerts";
import { Accordion } from "@/common/Accordion";

import { useUrlTextInputFilter } from "@/hooks/url-filters";

import { groupsAllUnitsTableNativeId } from "@/utils/native-ids";

import { useServerConfigQueryApi } from "@/api";

import { ServerConfigProps } from "@/types";

import BatteryReport from "./battery-report";

import { GroupHealthSection } from "./group/sections/GroupHealthSection";
import GroupDetails from "./group/cards/GroupDetails";
import { AllUnits } from "./group/sections/AllUnits";
import { GroupParkingCard } from "./group/cards/GroupParkingCard";
import { GroupWhiteLabelConfigCard } from "./group/cards/GroupWhiteLabelConfigCard";
import { ImportList } from "./group/cards/group-details/ImportList";
import { GroupIntegrations } from "./group/cards/GroupIntegrations";
import { GroupLockSettings } from "./group/cards/GroupLockSettings";
import { GroupUsersCard } from "./group/cards/GroupUsersCard";
import { HubHealthReportContainer } from "./group/cards/group-details/HubHealthReportContainer";
import { GroupDeviceBreakdownContainer } from "./group/cards/group-details/GroupDeviceBreakdownContainer";
import { PropertyHealthCard } from "./group/cards/PropertyHealthCard";
import { GroupWifiCard } from "./group/cards/GroupWifiCard";

function ResponsiveView({ children }: { children: React.ReactNode }) {
  const isMobileCard = useIsMobileCard();

  return (
    <View style={isMobileCard ? mobileStyles.cardWrapper : styles.cardWrapper}>
      {children}
    </View>
  );
}

export function Group() {
  const location = useLocation();
  const history = useHistory();
  const params = useParams<{ groupId: string }>();
  const drawer = useDrawerNav();

  const { inputValue, setInputValue, filterValue } = useUrlTextInputFilter(
    "name",
    {
      debounceInterval: 300,
    }
  );

  const { group, organization }: any = useGroup();

  const { data: isPropertyHealthCardEnabled } = useServerConfigQueryApi(
    (data: ServerConfigProps) => Boolean(data.propertyHealthCard.isEnabled)
  );

  const tab = Helpers.qsFromLocation(location)?.tab || "units";

  const { colors } = useTheme();
  const { permissions } = useUser();
  const isMobileCard = useIsMobileCard();

  const renderTabContent = () => {
    switch (tab) {
      case "projects":
        return <ProjectList searchValue={filterValue} group={group} />;
      case "unhealthy":
        return <GroupHealthSection searchValue={filterValue} />;
      case "units":
        return <AllUnits searchValue={filterValue} />;
      case "battery-report":
        return <BatteryReport groupId={params.groupId} />;
      case "imports":
        return <ImportList groupId={params.groupId} />;
      case "battery-powered-thermostats":
        return <BatteryPoweredThermostats searchValue={filterValue} />;
      default:
        return null;
    }
  };

  return (
    <Layout>
      <GroupAlerts />

      <View style={styles.content}>
        <Stack
          width="100%"
          flexChildren={true}
          spacing={8}
          direction={isMobileCard ? "column" : "row"}
        >
          <ManagerQuickActions
            smartLinks={[
              {
                title: "Group Overview",
                href: `/manager/groups/${group.id}/overview`,
                organizationUrl: organization.url,
              },
              {
                title: "Tours Settings",
                href: `/manager/groups/${group.id}/settings/tour/general`,
                organizationUrl: organization.url,
              },
            ]}
          />
          <QuickActions
            smartLinks={[
              {
                title: "Reports",
                onClick: () => drawer.push("groupReports", group),
                icon: Hamburger,
              },
            ]}
          />
        </Stack>
        <View style={styles.flexRow}>
          <View
            style={
              isMobileCard
                ? mobileStyles.cardWithActionsWrapper
                : styles.cardWithActionsWrapper
            }
          >
            <GroupDetails />
          </View>
          {isPropertyHealthCardEnabled ? (
            <ResponsiveView>
              <PropertyHealthCard />
            </ResponsiveView>
          ) : null}
          <ResponsiveView>
            <GroupIntegrations />
          </ResponsiveView>
          <ResponsiveView>
            <GroupUsersCard />
          </ResponsiveView>
          <ResponsiveView>
            <GroupLockSettings />
          </ResponsiveView>
          <ResponsiveView>
            <View style={styles.mb16}>
              <GroupParkingCard />
            </View>
            <View style={styles.mb16}>
              <GroupWhiteLabelConfigCard />
            </View>
          </ResponsiveView>
          <ResponsiveView>
            <GroupWifiCard />
          </ResponsiveView>
        </View>
        <View style={styles.mb16}>
          <Accordion title="Hub Health" initialOpenState={false}>
            <HubHealthReportContainer />
          </Accordion>
        </View>
        <View style={styles.mb16}>
          <Accordion title="Group IoT Health" initialOpenState={false}>
            <GroupDeviceBreakdownContainer />
          </Accordion>
        </View>

        <View
          style={[
            styles.filterWrapper,
            {
              borderColor: colors.border,
              backgroundColor: colors.pageBackground,
              paddingBottom: permissions.view_installs ? 0 : 16,
            },
          ]}
        >
          <TextInputField
            StartAdornment={Search}
            label={`Search for ${tab === "projects" ? "projects" : "units"}...`}
            textInputProps={{
              value: inputValue,
              onChangeText: setInputValue,
            }}
            EndAdornment={() =>
              inputValue ? (
                <TouchableOpacity onPress={() => setInputValue("")}>
                  <CloseCircleSolid color={colors.iconsWithinComponents} />
                </TouchableOpacity>
              ) : null
            }
          />

          <RadioFilters
            onChange={(value: string) => {
              Helpers.updateQS({ history, location, update: { tab: value } });
            }}
            currentScreen={tab}
            screens={[
              { label: "Units", key: "units" },
              { label: "Unhealthy Units", key: "unhealthy" },
              { label: "Battery Report", key: "battery-report" },
              ...(permissions?.view_installs
                ? [{ label: "Projects", key: "projects" }]
                : []),
              { label: "Imports", key: "imports" },
              {
                label: "Battery Powered Thermostats",
                key: "battery-powered-thermostats",
              },
            ]}
          />
        </View>
        <View style={styles.table} nativeID={groupsAllUnitsTableNativeId}>
          {renderTabContent()}
        </View>
      </View>
    </Layout>
  );
}

const mobileStyles = StyleSheet.create({
  cardWrapper: {
    margin: 8,
    width: "95%",
  },
  cardWithActionsWrapper: {
    margin: 8,
    width: "95%",
    zIndex: 9999,
  },
});

const styles = StyleSheet.create({
  content: { margin: 16 },
  mb16: { marginBottom: 16 },
  filterWrapper: {
    borderWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  cardWrapper: {
    marginRight: 16,
    marginBottom: 16,
    minWidth: 480,
  },
  cardWithActionsWrapper: {
    marginRight: 16,
    marginBottom: 16,
    minWidth: 480,
    zIndex: 9999,
  },
  flexRow: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  table: {
    maxHeight: "70vh",
    marginBottom: 36,
  },
});
