import { SRFeatureFlagsTable } from "./components/sr-feature-flags-table";

export const SRFeatureFlagsPage = () => {
  return (
    <div>
      <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl pb-4">
        SupportRent Feature Flags
      </h1>

      <SRFeatureFlagsTable />
    </div>
  );
};
